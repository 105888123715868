import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddIcon,
  AziendaIcon,
  ComuneIcon,
  DenominazioneIcon,
  DichiarazioniAd1Icon,
  EditIcon,
  FixIcon,
  GuideETutorialIcon,
  IndirizzoIcon,
} from "src/components/elements/AppIcons";
import React, { useMemo } from "react";
import { ImpiantoGreen } from "energix-types/src/Impianto";
import { Azienda } from "energix-types/src/Azienda";
import { useEditImpiantoGreenDialog } from "src/components/features/green/EditImpiantoGreenDialog";
import { UpdateImpiantoGreenFunction } from "src/actions/green/updateImpiantoGreen";
import { Link } from "react-router-dom";
import { datiByCodiceCatastale } from "energix-types/src/constants/datiComuniIstat";
import {
  ContatoreDto,
  EdistribuzioneContatoreDto,
  EdistribuzioneCredenzialiDto,
  GSEContatoreDto,
  GSECredenzialiDto,
  GSEDownloadTaskDto,
} from "src/orval/models";
import moment from "moment";

export type InfoImpiantoCardProps = {
  impianto: ImpiantoGreen;
  azienda: Azienda;
  aziende: Azienda[];
  contatoriEdistribuzione: EdistribuzioneContatoreDto[];
  contatoriGSE: GSEContatoreDto[];
  contatori: ContatoreDto[];
  updateImpiantoGreen: UpdateImpiantoGreenFunction;
  linkAzienda: string;
  linkImpianto?: string;
  onOpenImpianto?: () => void;
  edistribuzioneCredenziali: EdistribuzioneCredenzialiDto | null;
  gseCredenzali: GSECredenzialiDto | null;
  gseUltimoDownload: GSEDownloadTaskDto | false | null;
  listaEdistribuzioneCredenziali: EdistribuzioneCredenzialiDto[];
  listaGseCredenzali: GSECredenzialiDto[];

  openEdistribuzioneCredenzialiDialog: (azienda: Azienda) => void;
  openGSECredenzialiDialog: (azienda: Azienda) => void;
  openModificaCredenzialiEdistribuzione: (
    c: EdistribuzioneCredenzialiDto
  ) => void;
  openModificaCredenzialiGSE: (c: GSECredenzialiDto) => void;
};

const iconStyle = {
  color: (theme: Theme) => theme.palette.grey[600],
};

const labelStyle = {
  color: (theme: Theme) => theme.palette.grey[600],
  display: "inline",
};

export default function InfoImpiantoCard({
  impianto,
  azienda,
  aziende,
  contatoriEdistribuzione,
  contatoriGSE,
  contatori,
  updateImpiantoGreen,
  linkAzienda,
  linkImpianto,
  onOpenImpianto,
  edistribuzioneCredenziali,
  gseCredenzali,
  gseUltimoDownload,
  listaEdistribuzioneCredenziali,
  listaGseCredenzali,

  openEdistribuzioneCredenzialiDialog,
  openGSECredenzialiDialog,
  openModificaCredenzialiEdistribuzione,
  openModificaCredenzialiGSE,
}: InfoImpiantoCardProps) {
  const { editImpiantoDialog, openEditImpiantoGreenDialog } =
    useEditImpiantoGreenDialog(
      aziende,
      contatori,
      contatoriEdistribuzione,
      contatoriGSE,
      updateImpiantoGreen,
      listaEdistribuzioneCredenziali,
      listaGseCredenzali,
      openEdistribuzioneCredenzialiDialog,
      openGSECredenzialiDialog
    );

  const datiComune =
    datiByCodiceCatastale[impianto.codiceCatastaleImpianto ?? ""];

  const pods = useMemo(() => {
    return contatori.map((c) => c.pod);
  }, [contatori]);

  return (
    <Card style={{ height: "100%" }}>
      {editImpiantoDialog}
      <CardHeader
        title="Impianto"
        action={
          <>
            {(linkImpianto || onOpenImpianto) && (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<DichiarazioniAd1Icon />}
                onClick={onOpenImpianto}
                {...(linkImpianto
                  ? {
                      component: Link,
                      to: linkImpianto,
                    }
                  : {})}
              >
                Apri Dichiarazioni AD-1
              </Button>
            )}
          </>
        }
      />
      <CardContent>
        <List>
          <ListItem>
            <ListItemIcon>
              <DenominazioneIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={labelStyle}>Azienda: </Typography>
              <strong>{azienda.richiedenteNome}</strong>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DenominazioneIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={labelStyle}>Denominazione: </Typography>
              <strong>{impianto.name}</strong>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ComuneIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={labelStyle}>Comune sede: </Typography>
              <strong>{datiComune?.comune}</strong>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <IndirizzoIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={labelStyle}>Indirizzo: </Typography>
              <strong>{impianto.indirizzoImpianto}</strong>
            </ListItemText>
          </ListItem>
          {/*<ListItem>*/}
          {/*  <ListItemIcon>*/}
          {/*    <CodiceDittaIcon sx={iconStyle} />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText>*/}
          {/*    <Typography sx={labelStyle}>Identificativo GSE: </Typography>*/}
          {/*    <strong>TODO</strong>*/}
          {/*  </ListItemText>*/}
          {/*</ListItem>*/}

          {/*<ListItem>*/}
          {/*  <ListItemIcon>*/}
          {/*    <DenominazioneIcon sx={iconStyle} />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText>*/}
          {/*    <Typography sx={labelStyle}>Codice POD: </Typography>*/}
          {/*    <strong>TODO</strong>*/}
          {/*  </ListItemText>*/}
          {/*</ListItem>*/}
          <ListItem>
            <ListItemIcon>
              <GuideETutorialIcon sx={iconStyle} />
            </ListItemIcon>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ display: "flex", width: "100%" }}
            >
              <Typography sx={labelStyle}>E&#8209;Distribuzione: </Typography>
              <IntegrazioneGestoreBadge
                credenzialiStato={edistribuzioneCredenziali?.stato ?? null}
                credenzialiUltimoRinnovoPassword={
                  edistribuzioneCredenziali?.ultimoRinnovoPassword
                }
                hasCredenziali={!!azienda.credenzialiEdistribuzioneId}
                hasAtLeastOneContatore={
                  contatori.findIndex((ec) => pods.includes(ec.pod)) >= 0
                }
                ultimoDownloadStato={
                  edistribuzioneCredenziali?.ultimoDownloadTask?.stato ?? null
                }
                ultimoDownloadStatoError={
                  edistribuzioneCredenziali?.ultimoDownloadTask?.errore ?? null
                }
              />
            </Stack>
          </ListItem>
          {!edistribuzioneCredenziali ? (
            <Button
              size="small"
              variant="outlined"
              sx={{ ml: 7 }}
              startIcon={<AddIcon />}
              onClick={() => {
                openEdistribuzioneCredenzialiDialog(azienda);
              }}
            >
              Inserisci credenziali
            </Button>
          ) : null}
          {edistribuzioneCredenziali?.stato === "non_valide" ||
          edistribuzioneCredenziali?.stato === "password_scaduta" ? (
            <Button
              size="small"
              variant="outlined"
              sx={{ ml: 7 }}
              startIcon={<FixIcon />}
              onClick={() => {
                openModificaCredenzialiEdistribuzione(
                  edistribuzioneCredenziali
                );
              }}
            >
              Correggi credenziali
            </Button>
          ) : null}

          <ListItem>
            <ListItemIcon>
              <GuideETutorialIcon sx={iconStyle} />
            </ListItemIcon>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography sx={labelStyle}>GSE: </Typography>
              <IntegrazioneGestoreBadge
                credenzialiStato={gseCredenzali?.stato ?? null}
                hasCredenziali={!!azienda.credenzialiGSEId}
                hasAtLeastOneContatore={
                  contatori.findIndex((ec) => pods.includes(ec.pod)) >= 0
                }
                ultimoDownloadStato={
                  gseUltimoDownload ? gseUltimoDownload?.stato : null
                }
                ultimoDownloadStatoError={
                  gseUltimoDownload ? gseUltimoDownload?.errore : null
                }
              />
            </Stack>
          </ListItem>

          {!gseCredenzali ? (
            <Button
              size="small"
              variant="outlined"
              sx={{ ml: 7 }}
              startIcon={<AddIcon />}
              onClick={() => {
                openGSECredenzialiDialog(azienda);
              }}
            >
              Inserisci credenziali
            </Button>
          ) : null}
          {gseCredenzali?.stato === "non_valide" ? (
            <Button
              size="small"
              variant="outlined"
              sx={{ ml: 7 }}
              startIcon={<FixIcon />}
              onClick={() => {
                openModificaCredenzialiGSE(gseCredenzali);
              }}
            >
              Correggi credenziali
            </Button>
          ) : null}
        </List>

        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ mt: 2 }}
          spacing={1}
        >
          <Button
            variant="outlined"
            color="success"
            startIcon={<EditIcon />}
            onClick={() => openEditImpiantoGreenDialog(impianto)}
          >
            Modifica Impianto
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AziendaIcon />}
            component={Link}
            to={linkAzienda}
          >
            Apri Azienda
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

function IntegrazioneGestoreBadge({
  hasCredenziali,
  credenzialiStato,
  credenzialiUltimoRinnovoPassword,
  hasAtLeastOneContatore,
  ultimoDownloadStato,
  ultimoDownloadStatoError,
}: {
  credenzialiStato: null | "valide" | "non_valide" | "password_scaduta";
  credenzialiUltimoRinnovoPassword?: string | null;
  hasCredenziali: boolean;
  hasAtLeastOneContatore: boolean;
  ultimoDownloadStato: "in_coda" | "in_corso" | "successo" | "errore" | null;
  ultimoDownloadStatoError: string | null;
}) {
  let color: "error" | "warning" | "info" | "success";
  let text: string;

  if (!hasCredenziali) {
    color = "warning";
    text = "Credenziali non inserite";
  } else if (credenzialiStato === "non_valide") {
    color = "error";
    text = "Credenziali non valide";
  } else if (credenzialiStato === "password_scaduta") {
    color = "warning";

    if (credenzialiUltimoRinnovoPassword) {
      text = `Password scaduta (ultimo rinnovo il ${moment(
        credenzialiUltimoRinnovoPassword
      ).format("DD/MM/YYYY")})`;
    } else {
      text = "Password scaduta";
    }
  } else if (ultimoDownloadStato === "errore" && ultimoDownloadStatoError) {
    color = "error";
    text = `Errore: ${ultimoDownloadStatoError}`;
  } else if (!hasAtLeastOneContatore) {
    color = "info";
    text = "POD non mappati";
  } else if (ultimoDownloadStato === "in_coda") {
    color = "info";
    text = "Download in coda";
  } else if (ultimoDownloadStato === "in_corso") {
    color = "info";
    text = "Download in corso";
  } else {
    color = "success";
    if (credenzialiUltimoRinnovoPassword) {
      text = `Configurato (password rinnovata il ${moment(
        credenzialiUltimoRinnovoPassword
      ).format("DD/MM/YYYY")})`;
    } else {
      text = "Configurato";
    }
  }

  return (
    <Tooltip title={text}>
      <Chip
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          marginRight: 1,
          maxWidth: "100%",
          "& span": {
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
        }}
        size="small"
        color={color}
        label={<strong>{text}</strong>}
      />
    </Tooltip>
  );
}
